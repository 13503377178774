import {Link} from 'gatsby';
import React from 'react';
import PageTitle from './PageTitle';

import * as styles from './FeedPage.module.scss';

const FeedPage = ({feeds}) => {
  const handleFeedClick = (path) => {
    window.gtag &&
      window.gtag('event', 'click', {
        event_category: 'Feed',
        event_action: 'Open',
        event_label: path,
      });
  };

  const handleLinkClick = (link) => {
    window.gtag &&
      window.gtag('event', 'click', {
        event_category: 'Feed',
        event_action: 'Site',
        event_label: link,
      });
  };

  return (
    <>
      <div className={styles.Container}>
        <PageTitle title="Feeds" path="/feeds" />
        <div>
          {feeds
            .sort((a, b) => {
              return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
            })
            .map(({title, image, path, link, description}) => {
              return (
                <div className={styles.Link} key={path}>
                  <div className={styles.ImageContainer}>
                    {image ? <img src={image} alt="" /> : <div>{title[0]}</div>}
                  </div>
                  <div className={styles.TextContainer}>
                    <strong>{title}</strong>
                    {description && <div>{description}</div>}
                    <div className={styles.Links}>
                      <Link to={path} onClick={() => handleFeedClick(path)}>
                        View feed
                      </Link>
                      <a
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => handleLinkClick(link)}
                      >
                        Visit site
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default FeedPage;
