import React from 'react';
import {graphql} from 'gatsby';
import Page from '../components/Page';
import FeedPage from '../components/FeedPage';

const Feeds = ({data}) => {
  return (
    <Page
      title="Feeds"
      path="/feeds"
      description="Explore feeds from various sources for tech news, thoughts, and opinions."
      breadcrumb="/feeds"
    >
      <FeedPage feeds={data.allFeed.edges.map(({node}) => node)} />
    </Page>
  );
};

export default Feeds;

export const query = graphql`
  query FeedsQuery {
    allFeed {
      edges {
        node {
          id
          title
          path
          image
          lastBuildDate
          description
          link
        }
      }
    }
  }
`;
